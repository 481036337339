@tailwind base;
@tailwind components;
@tailwind utilities;

.prose code {
    word-break: break-all;
    font-weight: 500;
}

.prose code:before {
    content: "";
}

.prose code:after {
    content: "";
}

/* inline image */
#post-content p img {
    display: inline-block;
    margin: 0 auto;
}

.alert {
    @apply relative py-3 px-5 mb-4 border-[1px] border-transparent rounded-md;
}

.alert-warning {
    @apply bg-yellow-100 text-yellow-700 border-yellow-100;
}

.alert-info {
    @apply bg-blue-100 text-blue-700 border-blue-100;
}

.lyrics-table {
    @apply w-full max-w-full mb-4 bg-transparent;
}

.lyrics-table td,
.lyrics-table th {
    @apply p-3 align-top border-t-[1px] border-t-gray-200 dark:border-t-gray-500;
}

.lyrics-table td > p {
    line-height: 0.25rem;
}

.lyrics-table p > p,
.lyrics-table p > em,
.lyrics-table p > b,
.lyrics-table p > span {
    line-height: 1.5rem;
}

@layer components {
    .beside-link-icon {
        @apply w-8 h-8 rounded-md object-cover hover:-translate-y-1 transition;
    }
}

.prose-manga-synopsis {
    @apply prose dark:prose-invert;
    @apply prose-ul:-mt-4 prose-li:my-1 prose-ul:pl-0 prose-li:pl-0 md:prose-ul:pl-1 md:prose-li:pl-5;
    @apply prose-ul:list-none md:prose-li:before:content-['•_'] md:prose-li:before:-ml-2 md:prose-li:before:mr-1;
}
